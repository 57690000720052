/* global Component */
class sliderSectionComponent extends Component {

    static name() {
        return "sliderSectionComponent";
    }

    static componentName() {
        return "sliderSectionComponent";
    }

    getProps() {
        return  ['items','config'];
    }

    data() {
        return {
            hasMobileBanner: false,
            isMobile: $(window).width() <= 991
        };
    }

    mounted() {
        return function () {
            this.$nextTick(()=>{
                let searchOnlyMobileImage = this.items.findIndex((item, index)=>{
                    return item.fields.OnlyMobile == true;
                });
                this.hasMobileBanner = searchOnlyMobileImage != -1 && this.isMobile;

                $(this.$refs[this.UIComponentID]).carousel();
            });
        };
    }

    updated(){
        return function () {
            $(this.$refs[this.UIComponentID]).find('.carousel-item:first-child').addClass('active');
            $(this.$refs[this.UIComponentID]).carousel("cycle");
        };
    }

    getMethods() {
        return {
            isAppRouter:this.isAppRouter,
            itemCarouselMove:this.itemCarouselMove
        };
    }

    isAppRouter(item) {
        //console.log('APP LINK ',item.fields.ItemLink);
        if(item.fields.ItemLink && item.fields.ItemLink.length > 0 && (item.fields.ItemLink[0] === '#' || item.fields.ItemLink[0] === '/'  || item.fields.ItemLink[0] === '.'))
            return true;
        return false;
    }

    itemCarouselMove(direction){
        $(this.$refs[this.UIComponentID]).carousel(direction);
    }

    getTemplate() {
        return `<div class="justify-content-center mt-1 mb-1" style="margin:auto" >
                    <div :ref="UIComponentID" :id="UIComponentID" class="carousel slide carousel-fade sliderSectionComponent"  data-ride="carousel" >
                        <div class="carousel-inner">
                           <template v-for="(item,index) in items">
                               <template v-if="item.fields.ItemPhotoUri">
                                    <template v-if="(!hasMobileBanner && !item.fields.OnlyMobile) || (hasMobileBanner && item.fields.OnlyMobile)">
                                        <div class="carousel-item"  :class="{active:index==0} ":href="item.fields.ItemLink">
                                        <template v-if="item.fields.ItemLink">
                                            <template v-if="isAppRouter(item)">
                                                <router-link :to="item.fields.ItemLink.replace('#','')" >
                                                        <img :src="item.fields.ItemPhotoUri" alt="Sider" class="d-block">
                                                </router-link>
                                            </template>
                                            <template v-else>
                                                    <a target="_blank" :href="item.fields.ItemLink" >
                                                        <img :src="item.fields.ItemPhotoUri" alt="Sider" class="d-block" >
                                                    </a>
                                            </template>
                                        </template>
                                        <template v-else>
                                                <img :src="item.fields.ItemPhotoUri" alt="Sider" class="d-block">
                                        </template>
                                        </div>
                                    </template>
                               </template>
                           </template>
                        </div>

                        <template v-if="items.length > 1">
                            <a class="carousel-control-prev" :href="'#'+UIComponentID" role="button" data-slide="prev" @click.prevent="itemCarouselMove('prev')">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" :href="'#'+UIComponentID" role="button" data-slide="next" @click.prevent="itemCarouselMove('next')">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </template>
                    </div>
                </div>`;
    }
}

sliderSectionComponent.registerComponent();